// Passive event listeners
jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};

$(document).ready(function () {

    $('.js-button-url').on('click', function(){
        //console.log($(this).data('url'));
        window.location.href = $(this).data('url');
    });

    $('.js-slider-adn').slick({
        dots : true,
        arrows : false,
        autoplay : true,
        autoplaySpeed : 6000,
        pauseOnHover : true,
        //fade: true,
    });

    $('.js-slider-avis').slick({
        dots: false,
        arrows: true,
        autoplay: false,
        slidesToShow: 4,
        prevArrow: '.avis-verifies__prev',
        nextArrow: '.avis-verifies__next',
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1090,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        //fade: true,
    });

    


/* COOKIES */
    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
   
    if (document.cookie.indexOf('custom-message=') == '-1'){
        $('.custom-message').slideDown(200);
        if( window.innerWidth > 700 ){
            setTimeout(function () {
                let messageHeight = $('.custom-message').innerHeight();
                $('.hp-title').animate({ 'marginTop': -messageHeight + 'px' }, 200);
            }, 250);
        }
    }


/* CAROUSEL */
    $('.js-carousel').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        //fade: true,
    });
/* END CAROUSEL */


/* ANCHOR SMOOTH SCROLL */
    $('.anchors__item, .smooth-scroll-item').on('click', function () {
        var page = $(this).attr('href');
        var distance = (($(page).offset().top) - $('.header').innerHeight());
        var speed = 750;
        $('html, body').animate({ scrollTop: distance }, speed);
        return false;
    });
    
/* END ANCHOR SMOOTH SCROLL */


/* LOCK / RELEASE SCROLL */
    function lockScroll() {
        let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
        document.querySelector('html').classList.add('noscroll');
        document.querySelector('html').style.top = -scrollTop + 'px';
    }
    function releaseScroll() {
        let scrollTop = parseInt(document.querySelector('html').style.top);
        document.querySelector('html').classList.remove('noscroll');
        document.querySelector('html,body').scrollTop = -scrollTop;
    }
/* END LOCK / RELEASE SCROLL */


/* ON SCROLL */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('.header').addClass('scroll');
        } else {
            $('.header').removeClass('scroll');
        }
    });
/* END ON SCROLL */


/* MENU OPEN CLOSE */
    $('.js-toogle-menu').on('click', function () {
        if( $(this).hasClass('active') ){
            releaseScroll()
            $(this).removeClass('active');
            $('.header').removeClass('menu-on');
            if( !$('body').hasClass('homepage') ){
                $('.logo__img--white').fadeOut(250);
            }
            if ($('body').hasClass('homepage') && $('header').hasClass('scroll')) {
                $('.logo__img--white').fadeOut(250);
            }
            $('.btn-menu__text').fadeIn(250);
            $('.main-menu').fadeOut(250);
            if ( !$('.custom-message').hasClass('masked')) {
                $('.custom-message').show(0);
            }
        } 
        else{
            lockScroll();
            $(this).addClass('active');
            $('.header').addClass('menu-on');
            $('.logo__img--white').fadeIn(250);
            $('.btn-menu__text').fadeOut(250);
            $('.main-menu').fadeIn(250);
            if ( !$('.custom-message').hasClass('masked') ){
                $('.custom-message').hide(0);
            }
        }
    });
/* MENU OPEN CLOSE */


/* ACCORDION */
    $('.accordion__item .item__title').on('click', function () {
        if ($(this).hasClass('active') ){
            $(this).removeClass('active');
            $(this).closest('.accordion').find('.item__text').slideUp(250);
        }
        else {
            $('.item__title').removeClass('active');
            $(this).addClass('active');
            $(this).closest('.accordion').find('.item__text').slideUp(250);
            $(this).parent().find('.item__text').slideDown(250);
        }
    });
/* END ACCORDION */




/* FILTERS */
    let filters = [];

    function filterElements(filter, action){
        if ( action == 'remove') {
            const indexFilter = filters.indexOf(filter);
            filters.splice(indexFilter, 1);
        }
        else {
            filters.push(filter);
        }

        $('.to-filter').addClass('hide');

        var classes = '\\b(' + filters.join('|') + ')\\b', regex = new RegExp(classes, 'i');
        $('.to-filter').each(function () {
            var elClasses = ' ' + $(this).attr('class').replace(/\s+/, ' ') + ' ';
            if (regex.test(elClasses)) {
                $(this).removeClass('hidden');
                var $this = $(this);
                setTimeout(function () { $this.removeClass('hide') }, 250);
            }
            else {
                var $this = $(this);
                $this.addClass('hidden')

            }
        });
    }

    function reinitFilters(){
        $('.filter-button').removeClass('active');
        $('.to-filter').addClass('hide');
        filters = [];
        setTimeout(function () { $('.to-filter').removeClass('hidden') }, 250);
        setTimeout(function () { $('.to-filter').removeClass('hide') }, 255);
    }


    $('.filter-button').on('click', function () {
        let filter = $(this).data('filter');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            filterElements(filter, 'remove');
            
        }
        else {
            $(this).addClass('active');
            filterElements(filter, 'add');
        }
    });
        

    $('.filters__see-all').on('click', function () {
        reinitFilters();
    });
    
/* FILTERS */


/* FILTER BOOK ELEMENTS WITH URL */
    let searchParams = new URLSearchParams(window.location.search)
    if( searchParams.has('filter') ) {
        let filterURL = searchParams.get('filter')
        filterElements(filterURL, 'add');
        $('.filter-button[data-filter="' + filterURL+'"]').addClass('active');
    } 


/* CUSTOM MESSAGE (HEADER) */
    $('.custom-message__close').on('click', function () {
        $('.custom-message').addClass('masked');
        $('.custom-message').slideUp(200);
        setCookie('custom-message', 'hide', 1);
        if (window.innerWidth > 700) {
            $('.hp-title').animate({'marginTop' : 0 }, 200);
        }
    });    



});
    

